import nav from './modules/nav';
import closeModals from './modules/alert-modal';
import internal_dropdonws_set_match_height from './modules/internal-dropdowns-set-match-height';
import internal_dropdonws_set_trigger_class from './modules/internal-dropdowns-set-trigger-class';

$(() => {

  nav();
  closeModals();
  internal_dropdonws_set_match_height();
  internal_dropdonws_set_trigger_class();
});
