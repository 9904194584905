import { prefix } from '../helpers/constants';

export default () => {
  const $travelAlert = $(`.${prefix}-m-travel-alert`);
  const $closeModalBtns = $travelAlert.find('.close-modal');
  const closeAction = (item, addCookie = false) => {
    const $parent = $(item).parents(`.${prefix}-m-travel-alert`);
    const cookieName = $parent.data('cookie-name');

    if (addCookie) {
      $.cookie(cookieName, 'yes');
    }

    if (typeof cookieName !== 'undefined' && typeof $.cookie(cookieName) !== 'undefined') {
      $parent.attr('hidden', '').remove();
    } else {
      $parent.removeAttr('hidden');
    }
  };

  if ($closeModalBtns.length) {
    $.each($travelAlert, (index, item) => {
      closeAction($(item).find('.close-modal'));
    });
  }

  $closeModalBtns.click((e) => {
    closeAction(e.target, true);
  });
}
